// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { ref, push, onValue, getDatabase } from 'firebase/database';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.FIRESTORE_API_KEY,
  authDomain: "usauemails.firebaseapp.com",
  databaseURL: process.env.FIRESTORE_DATABASE_URL,
  projectId: "usauemails",
  storageBucket: "usauemails.appspot.com",
  messagingSenderId: "316865694451",
  appId: process.env.FIRESTORE_APP_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getDatabase(app);

export function addEmail(newEmail) {
    const emailsRef = ref(db, 'usauemails');
    push(emailsRef, newEmail)
      .then(() => {
        console.log('Data saved successfully!');
      })
      .catch((error) => {
        console.error('Failed to write data:', error);
      });
  }

export function fetchEmailsCount(callback) {
    const path = 'usauemails';
    const dataRef = ref(db, path);

    onValue(dataRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
            const length = Object.keys(data).length;
            callback(length);
        }
    }, {
        onlyOnce: true // This ensures the listener is called only once
    });
}