import React, { useState } from 'react';
import { TextField, Button, CssBaseline, Container, Typography, Paper, Accordion, AccordionSummary, AccordionDetails, Checkbox, FormControlLabel } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import * as db from './datastore';

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#00e676',
    },
    background: {
      default: '#000000',
    },
  },
}); 

const emailBody = `You are the voice of ultimate players in our country and I am urging you to vote YES on Proposal B at the WFDF Congress to call for a ceasefire in the Middle East. The agenda item is scheduled for Saturday August 31st. If you are unable to vote yes, then abstaining entirely is the next best option. With ten votes in the Congress, you have the power to get this proposal over the finish line. Please support this humanitarian call that aligns with the values of SOTG.`

function App() {
  const [name, setName] = useState('');
  const [team, setTeam] = useState('');
  const [isUsauMember, setIsUsauMember] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const randNum = Math.floor(Math.random()*100);
  
  const emailSubject = 'Calling on USAU to Vote Yes on Proposal B - ' + randNum;

  let nameSentence = '';
  if (name) {
    if (isUsauMember) {
      nameSentence = `My name is ${name}, and I am a USAU member.`
    } else {
      nameSentence = `My name is ${name}.`
    }
  }
  const teamSentence = team ? `I'm affiliated with ${team}.` : '';
  const fullEmail = `USAU Board Members and Staff, \n\n${nameSentence} ${teamSentence} ${emailBody} \n\n Thanks, ${name}`;
  const primaryEmail = "dave.klink@usaultimate.org";
  const ccEmails = ['tom@hq.usaultimate.org', 'leslie.gamez@usaultimate.org', 'mike.edmonds@usaultimate.org', 'deanna.ball@usaultimate.org', 'isaiah.bryant@usaultimate.org', 'tom@hq.usaultimate.org', 'caleb.denecour@usaultimate.org', 'jimmy.donnellon@usaultimate.org', 'janna.hamaker@usaultimate.org','pawel.janas@usaultimate.org', 'benecia.newhouse@usaultimate.org', 'chris.novielli@usaultimate.org', 'ben.vanheuvelen@usaultimate.org', 'will@hq.usaultimate.org', 'marc@hq.usaultimate.org', 'marc@hq.usaultimate.org', 'david.raflo@hq.usaultimate.org' ];
  const ccParam = ccEmails.join(",");
  const ccInText = ccEmails.join(", ");
  const mailtoLink = `mailto:${primaryEmail}?cc=${encodeURIComponent(ccParam)}&subject=${encodeURIComponent(emailSubject)}&body=${encodeURIComponent(fullEmail)}`;

  const handleAccordionChange = (isExpanded) => {
    setExpanded(isExpanded);
  };

  const handleGenerateEmail = () => {
    const email = {
      name: name,
      team: team, 
      isUsauMember: isUsauMember,
    }

    db.addEmail(email);
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="sm" align="center">
        <Typography variant="h4" align="center" marginTop={4} gutterBottom>
          Calling on USAU to Vote Yes on Proposal B
        </Typography>
        <Paper style={{ padding: '20px', marginTop: '30px' }}>
          <Typography variant="body1">
            Use the form below to call on USAU to vote yes on Proposal B at the annual WFDF Congress. Proposal B was initiated by Indonesia, and would require WFDF to release a statement in support of a ceasefire. Read more about it on <a className='white-link' href='https://www.instagram.com/globalultimateforceasefire/?igsh=b2J1MXFqY3U2ZjQ4'>Instagram</a>.
          </Typography>
        </Paper>
        <Paper elevation={2} style={{ padding: '20px', marginTop: '30px' }}>
          <Typography variant="body1" align="center" gutterBottom>
            The form will generate an email in your email app. Just hit send!
          </Typography>
          <TextField
            label="Name"
            variant="outlined"
            fullWidth
            margin="normal"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            label="Affilitation (Team, Organization, City) (Optional)"
            variant="outlined"
            fullWidth
            margin="normal"
            value={team}
            onChange={(e) => setTeam(e.target.value)}
          />
          <FormControlLabel 
            id='isUsauMember'
            variant='body1'
            control={
              <Checkbox 
                checked={isUsauMember} 
                onChange={(e) => setIsUsauMember(!isUsauMember)} 
              />
            } 
            label="Are you a USAU member?" />
          <Button
            variant="contained"
            color="primary"
            href={mailtoLink}
            disabled={!name}
            style={{ marginTop: '20px' }}
            onClick={handleGenerateEmail}
          >
            Generate Email
          </Button>
        </Paper>
        <Accordion expanded={expanded} onChange={() => handleAccordionChange(!expanded)} style={{ marginTop: '20px', marginBottom: '20px' }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>View Email Details</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1" align="left"><b>Email:</b> {primaryEmail}</Typography>
            <Typography variant="body1" align="left"><b>CC:</b> {ccInText}</Typography>
            <Typography variant="body1" align="left"><b>Subject:</b> {emailSubject}</Typography>
            <Typography variant="body1" align="left"><b>Body:</b> {emailBody}</Typography>
          </AccordionDetails>
        </Accordion>
      </Container>
    </ThemeProvider>
  );
}

export default App;
